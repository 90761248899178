import { EventEmitter } from 'events';
import QueueApi from '../apis/QueueApi';
import AuthStore from './AuthStore';
import { UserImpersonation } from 'utils/UserImpersonation';

class CareQueueStore extends EventEmitter {
  private QueueApi: QueueApi;
  private QueueList: any[] = [];
  private PendingQueueList: any[] = [];
  private QueueNumber: number;
  private QueueEmergencyList: any[] = [];
  private QueueRequestList: any[] = [];
  private LastCallRequest: any;
  private PollingQueueNumber: boolean;

  constructor() {
    super();

    this.QueueApi = new QueueApi(this);
    this.QueueList = localStorage.QueueList || [];
    this.PendingQueueList = localStorage.QueueList || [];
    this.QueueNumber = localStorage.QueueNumber;

    this.QueueEmergencyList = localStorage.QueueEmergencyList || [];
    this.QueueRequestList = localStorage.QueueRequestList || [];

    this.LastCallRequest = localStorage.LastCallRequest || {};

    this.PollingQueueNumber = false;
  }

  getQueue() {
    return this.QueueList;
  }

  getPendingRequest() {
    let request = null;
    if (typeof this.PendingQueueList === 'object' && this.PendingQueueList.length > 0) {
      request = this.PendingQueueList[0];
      for (const element of this.PendingQueueList) {
        if (element.IsEmergency) {
          request = element;
          break;
        }
      }
    }
    this.emit('onGetQueueSize', { request: request });
  }

  getLastPendingRequest() {
    let request = null;
    if (typeof this.PendingQueueList === 'object' && this.PendingQueueList.length > 0) {
      request = this.PendingQueueList[0];
      for (const element of this.PendingQueueList) {
        if (element.IsEmergency) {
          request = element;
          break;
        }
      }
    }
    return request;
  }

  updatePendingRequest() {
    let request = null;
    if (typeof this.PendingQueueList === 'object' && this.PendingQueueList.length > 0) {
      request = this.PendingQueueList[0];
      for (const element of this.PendingQueueList) {
        if (element.IsEmergency) {
          request = element;
          break;
        }
      }
    }
    this.emit('onQueueChanged', { request: request });
  }

  updateCachePendingRequest(queueList) {
    this.PendingQueueList = []; //queueList;

    if (typeof queueList === 'object' && queueList.length > 0) {
      console.log('-----------> Inside');
      for (const element of queueList) {
        if (!element.IsAddressed) {
          // console.log("QUEUE PENDING REQUEST ADDING 1");
          this.PendingQueueList.push(element);
        } else {
          // console.log("QUEUE PENDING REQUEST SKIPPING 1", queueList[i]);
        }
      }
    }

    let request = null;

    if (typeof this.PendingQueueList === 'object' && this.PendingQueueList.length > 0) {
      request = this.PendingQueueList[0];
      for (const element of this.PendingQueueList) {
        if (element.IsEmergency) {
          request = element;
          break;
        }
      }
    }

    this.emit('onQueueChanged', { request: request });
  }

  getQueueNumber() {
    return this.QueueNumber;
  }

  setQueueNumber(value) {
    this.QueueNumber = value;
  }

  getLastRequest() {
    return this.LastCallRequest;
  }

  getPendingRequestByPatient(patientID, deviceID) {
    console.log('getPendingRequestByPatient', patientID, deviceID);
    patientID = patientID.toLowerCase();
    deviceID = deviceID.toLowerCase();

    let pendingRequestID = '';
    for (let i = 0; i < this.QueueEmergencyList.length; i++) {
      console.log('Reviewing 1', this.QueueEmergencyList[i].PatientID);
      if (this.QueueEmergencyList[i].PatientID == patientID && this.QueueEmergencyList[i].DeviceID == deviceID) {
        console.log('We got it !!!', this.QueueEmergencyList[i].PatientID);
        pendingRequestID = this.QueueEmergencyList[i].RequestID;
        break;
      }
    }

    if (pendingRequestID != '') {
      console.log('Taken from EmergencyList P&D');
      return pendingRequestID;
    }

    for (let i = 0; i < this.QueueRequestList.length; i++) {
      console.log('Reviewing 2', this.QueueRequestList[i].PatientID);
      if (this.QueueRequestList[i].PatientID == patientID && this.QueueRequestList[i].DeviceID == deviceID) {
        console.log('We got it !!!', this.QueueRequestList[i].PatientID);
        pendingRequestID = this.QueueRequestList[i].RequestID;
        break;
      }
    }

    if (pendingRequestID != '') {
      console.log('Taken from QueueRequestList P&D');
      return pendingRequestID;
    }

    // Second Part
    for (let i = 0; i < this.QueueEmergencyList.length; i++) {
      console.log('Reviewing 3', this.QueueEmergencyList[i].PatientID);
      if (this.QueueEmergencyList[i].PatientID == patientID) {
        console.log('We got it !!!', this.QueueEmergencyList[i].PatientID);
        pendingRequestID = this.QueueEmergencyList[i].RequestID;
        break;
      }
    }

    if (pendingRequestID != '') {
      console.log('Taken from EmergencyList P');
      return pendingRequestID;
    }

    for (const element of this.QueueRequestList) {
      console.log('Reviewing 4', element.PatientID);
      if (element.PatientID == patientID) {
        console.log('We got it !!!', element.PatientID);
        pendingRequestID = element.RequestID;
        break;
      }
    }

    console.log('Taken from QueueRequestList P');
    return pendingRequestID;
  }

  getEmergencyList() {
    return this.QueueEmergencyList;
  }
  getRequestList() {
    return this.QueueRequestList;
  }

  setPollingQueueNumber(polling) {
    this.PollingQueueNumber = polling;
  }

  startPollingQueue() {
    this.emit('onStartPollingQueue');
  }

  broadcastStopNotification() {
    this.emit('onQueueItemClicked');
  }

  removeRequestFromQueue(requestID) {
    console.log('CLEANING QUEUE: ', this.QueueNumber);

    const emergencyList = [];
    const requestList = [];
    let requestToRemoveIndex = -1;
    for (let i = 0; i < this.QueueList.length; i++) {
      if (this.QueueList[i].RequestID != requestID) {
        if (this.QueueList[i].IsEmergency) {
          emergencyList.push(this.QueueList[i]);
        } else {
          requestList.push(this.QueueList[i]);
        }
      } else {
        requestToRemoveIndex = i;
      }
    }
    this.QueueEmergencyList = emergencyList;
    this.QueueRequestList = requestList;

    if (requestToRemoveIndex > -1) {
      this.QueueList.splice(requestToRemoveIndex, 1);
    }

    this.QueueNumber = this.QueueList.length;
    const index = this.QueueList.length;
    if (index > 0) {
      this.LastCallRequest = this.QueueList[index - 1];
    } else {
      this.LastCallRequest = null;
    }

    this.PendingQueueList = []; //queueList;
    for (const element of this.QueueList) {
      if (!element.IsAddressed) {
        this.PendingQueueList.push(element);
      }
    }
    this.saveToLocalStorage();
    this.updatePendingRequest();
    this.emit('onHandledRequestRemovedFromStore');
    console.log('CLEANING QUEUE: ', this.QueueNumber);
  }

  // *
  markRequestAddressed(requestID) {
    const tempPendingQueueList = this.PendingQueueList;
    this.PendingQueueList = [];

    for (const element of tempPendingQueueList) {
      if (element.RequestID != requestID) {
        this.PendingQueueList.push(element);
        break;
      } else {
        console.log('markRequestAddressed skipped', element.RequestID);
      }
    }

    this.updatePendingRequest();
    console.log('CLEANING TOPBAR');
  }

  // WS called within the screen QUEUE
  getCareCallRequestsQueueForUser({ UserID }) {
    console.log('getCareCallRequestsQueueForUser ', UserID);

    if (AuthStore.isProvider() && !UserImpersonation.isImpersonating()) {
      console.log('Not calling getCareCallRequestsQueueForUser for provider-user');
      return;
    }

    this.QueueApi.getCareCallRequestsQueueForUser({ UserID }, rsp => {
      console.log('Pollinf Queue', rsp, this.PollingQueueNumber);

      if (rsp && rsp.ok && !this.PollingQueueNumber) {
        this.QueueList = rsp.requests;
        this.QueueNumber = rsp.requests.length;
        console.log('GETTING QUEUE: ', this.QueueNumber);

        const emergencyList = [];
        const requestList = [];
        for (const element of rsp.requests) {
          if (element.IsEmergency) {
            emergencyList.push(element);
          } else {
            requestList.push(element);
          }
        }
        this.QueueEmergencyList = emergencyList;
        this.QueueRequestList = requestList;
        this.saveToLocalStorage();

        const index = rsp.requests.length;
        if (index > 0) {
          this.LastCallRequest = rsp.requests[index - 1];
        } else {
          this.LastCallRequest = null;
        }

        this.PendingQueueList = [];
        for (const element of this.QueueList) {
          if (!element.IsAddressed) {
            this.PendingQueueList.push(element);
          }
        }
        this.updatePendingRequest();
      }

      this.emit('onGetCareCallRequestsQueue', rsp && rsp.ok);
    });
  }

  getCareQueueNumber({ UserID }) {
    if (AuthStore.isProvider() && !UserImpersonation.isImpersonating()) {
      console.log('Not calling getCareCallRequestsQueueForUser for provider-user');
      return;
    }
    this.QueueApi.getCareCallRequestsQueueForUser({ UserID }, rsp => {
      const emergencyList = [];
      const requestList = [];
      if (rsp && rsp.requests) {
        for (let i = 0; i < rsp.requests.length; i++) {
          if (rsp.requests[i].IsEmergency) {
            emergencyList.push(rsp.requests[i]);
          } else {
            requestList.push(rsp.requests[i]);
          }
        }
      }
      this.QueueEmergencyList = emergencyList;
      this.QueueRequestList = requestList;

      const requestArray = rsp && rsp.requests ? rsp.requests : [];
      const index = requestArray.length;
      if (index > 0) {
        this.LastCallRequest = rsp.requests[index - 1];
      } else {
        this.LastCallRequest = null;
      }

      const responseOk = rsp && rsp.ok;
      this.updateCachePendingRequest(requestArray);
      this.emit('onGetQueueNumber', { ok: responseOk, number: requestArray.length });
    });
  }

  reportCallHandled({ RequestID, UserID, Notes }: { RequestID: string; UserID: string; Notes?: string }) {
    console.log('reportCallHandled', RequestID, UserID, Notes);
    return new Promise(resolve => {
      this.QueueApi.ReportCallRequestHandled({ RequestID, UserID, Notes }, rsp => {
        rsp.requestID = RequestID;

        this.emit('onReportCallHandled', rsp);
        resolve(rsp);
      });
    });
  }

  reportCallAddressed({ RequestID, UserID, Notes }) {
    console.log('reportCallAddressed ', RequestID, UserID, Notes);

    this.QueueApi.ReportCallAddressed({ RequestID, UserID, Notes }, rsp => {
      this.emit('onReportCallAddressed', rsp);
    });
  }

  generateNotification() {
    this.emit('onGenerateNotification');
  }

  stopNotificationSound() {
    this.emit('onStopNotificationSound');
  }

  saveToLocalStorage() {
    console.log('saveToLocalStorage');

    localStorage.QueueList = this.QueueList;
    localStorage.QueueNumber = this.QueueNumber;

    localStorage.QueueEmergencyList = this.QueueEmergencyList;
    localStorage.QueueRequestList = this.QueueRequestList;
  }

  requestOpenMarkAsHandledModal({ RequestID, UserID, PatientName, StartTime, Emergency, Escalated, Requests, Notes, DeviceOffset, TimezoneString }) {
    console.log('requestOpenMarkAsHandledModal', RequestID, UserID, PatientName, StartTime, Emergency, Escalated, Requests, Notes, DeviceOffset, TimezoneString);

    // this.QueueApi.({ RequestID, UserID, PatientName, StartTime, Emergency, Escalated, Requests, Notes }, (rsp) => {

    this.emit('onOpenMarkAsHandledModal', {
      ID: RequestID,
      userID: UserID,
      patientName: PatientName,
      startTime: StartTime,
      emergency: Emergency,
      escalated: Escalated,
      requests: Requests,
      notes: Notes,
      deviceOffset: DeviceOffset,
      timezoneString: TimezoneString,
    });
    // });
  }
}

const careQueueStore = new CareQueueStore();

export default careQueueStore;
