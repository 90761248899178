//@ts-nocheck
import { EventEmitter } from 'events';
import UserApi from '../apis/UserApi';

class UserStore extends EventEmitter {
  constructor() {
    super();

    this.UserApi = new UserApi(this);
    this.UserID = localStorage.UserID;
    this.UserAccountID = localStorage.UserAccountID;
    this.UserFirstName = localStorage.UserFirstName;
    this.UserLastName = localStorage.UserLastName;
    this.UserFullName = localStorage.UserFullName;
    this.UserEmail = localStorage.UserEmail;
    this.UserCreatedDate = localStorage.UserCreatedDate;
    this.UserTelephone = localStorage.UserTelephone;
    this.UserAddress = localStorage.UserAddress;
    this.UserIsAdmin = localStorage.UserIsAdmin;
    this.UserIsOnDuty = localStorage.UserIsOnDuty;
    this.UserOnlyUrgent = localStorage.UserOnlyUrgent;
    this.UserAllPatients = localStorage.UserAllPatients;

    this.UserImage = localStorage.UserImage;

    this.ShowCallButton = localStorage.ShowCallButton || false;
    this.ShowCheckInButton = localStorage.ShowCheckInButton || false;
    this.ShowEmergencyButton = localStorage.ShowEmergencyButton || false;

    //console.warn(this);
  }

  getID() {
    return this.UserID;
  }
  getAccountID() {
    return this.UserAccountID;
  }

  getFirstName() {
    return this.UserFirstName;
  }

  getLastName() {
    return this.UserLastName;
  }

  getFullName() {
    return this.UserFullName;
  }

  getEmail() {
    return this.UserEmail;
  }

  getCreatedDate() {
    return this.UserCreatedDate;
  }

  getTelephone() {
    return this.UserTelephone;
  }

  getAddress() {
    return this.UserAddress;
  }

  getIsAdmin() {
    return this.UserIsAdmin;
  }

  getIsOnDuty() {
    return this.UserIsOnDuty;
  }

  getOnlyUrgent() {
    return this.UserOnlyUrgent;
  }

  getAllPatientsAccess() {
    return this.UserAllPatients;
  }

  getImageUrl() {
    return this.UserImage;
  }

  getShowCallButton() {
    return this.ShowCallButton;
  }

  getShowCheckInButton() {
    return this.ShowCheckInButton;
  }

  getShowEmergencyButton() {
    return this.ShowEmergencyButton;
  }

  getUserData({ UserID }) {
    console.log('getUserData ', UserID);

    this.UserApi.GetCareUserData({ UserID }, rsp => {
      console.log('getUserData Response');
      console.log(rsp);

      if (rsp.ok) {
        this.UserID = rsp.UserID;
        this.UserAccountID = rsp.AccountID;
        this.UserFirstName = rsp.FirstName;
        this.UserLastName = rsp.LastName;
        this.UserFullName = rsp.FirstName + ' ' + rsp.LastName;
        this.UserEmail = rsp.Email;
        this.UserCreatedDate = rsp.CreatedDate;
        this.UserTelephone = rsp.Telephone;
        this.UserAddress = rsp.Address;
        this.UserIsAdmin = rsp.IsAdmin;
        this.UserIsOnDuty = rsp.IsOnDuty;
        this.UserOnlyUrgent = rsp.OnlyUrgent;
        this.UserAllPatients = rsp.SeeAllPatients;
        this.UserImage = rsp.UserImageThumb ? rsp.UserImageThumb : '/img/user_placeholder.png';

        this.ShowCallButton = rsp.ShowCallButton ? true : false;
        this.ShowCheckInButton = rsp.ShowCheckInButton ? true : false;
        this.ShowEmergencyButton = rsp.ShowEmergencyButton ? true : false;

        this.saveToLocalStorage();
      }

      this.emit('onUserGetData', rsp.ok);
    });
  }

  UpdateUserData({ UserID, FirstName, LastName, Email, Telephone, Address, Password, NewPassword, UpdateUserData, UpdateUserPassword, IsAdmin, IsOnDuty }) {
    console.log('UpdateUserData ', UserID, FirstName, LastName, Email, Telephone, Address, Password, NewPassword, UpdateUserData, UpdateUserPassword, IsAdmin, IsOnDuty);

    this.UserApi.UpdateCareUserData({ UserID, FirstName, LastName, Email, Telephone, Address, Password, NewPassword, IsAdmin, IsOnDuty }, rsp => {
      console.log('Nucleus Response');
      console.log(rsp);

      if (rsp.ok) {
        this.UserFirstName = FirstName;
        this.UserLastName = LastName;
        this.UserFullName = FirstName + ' ' + LastName;
        this.UserEmail = Email;
        this.UserTelephone = Telephone;
        this.UserAddress = Address;

        this.saveToLocalStorage();
      }

      this.emit('onUserUpdateData', {
        ok: rsp.ok,
        credentials: rsp.okCredentials,
        passwordUpdated: rsp.PasswordUpdated,
        passwordUpdateError: rsp.PasswordUpdateError,
        updateUserData: UpdateUserData,
        updateUserPassword: UpdateUserPassword,
        internetError: rsp.errorInternetConnection,
      });
    });
  }

  setUserOnDuty({ UserID, IsOnDuty }) {
    console.log('setUserOnDuty ', UserID, IsOnDuty);

    this.UserApi.setUserOnDuty({ UserID, IsOnDuty }, rsp => {
      console.log('setUserOnDuty Response');
      console.log(rsp);

      //*-
      this.emit('onSetUserOnDuty', {
        ok: rsp.ok,
        onDuty: rsp.IsOnDuty,
        userId: UserID,
      });
    });
  }

  setSeeAllPatients({ UserID, CanSee }) {
    console.log('setSeeAllPatients ', UserID, CanSee);

    this.UserApi.SetSeeAllPatients({ UserID, CanSee }, rsp => {
      console.log('setSeeAllPatients Response');
      console.log(rsp);

      //*-
      this.emit('onSetSeeAllPatients', {
        ok: rsp.ok,
        canSee: rsp.canSee,
        userId: UserID,
      });
    });
  }

  getDailyCalls({ AccountID }) {
    console.log('getDailyCalls ', AccountID);

    this.UserApi.getDailyCalls({ AccountID }, rsp => {
      this.emit('onGetDailyCallsAction', rsp);
      this.emit('onGetDailyCallsResponse', rsp);
    });
  }

  getDailyHandledCalls({ AccountID }) {
    this.UserApi.getDailyHandledCalls({ AccountID }, rsp => {
      this.emit('onGetDailyHandledCallsAction', rsp);
    });
  }

  getFamilyMembers({ AccountID }) {
    console.log('getFamilyMembers ', AccountID);

    this.UserApi.GetFamilyMembers({ AccountID }, rsp => {
      console.log('getFamilyMembers Response');
      console.log(rsp);

      this.emit('onGetFamilyMembers', rsp);
    });
  }

  getMessagesReport({ AccountID }) {
    console.log('getMessagesReport ', AccountID);

    this.UserApi.GetMessagesReport({ AccountID }, rsp => {
      this.emit('onGetMessagesReport', rsp);
    });
  }

  getFamilyCalls({ AccountID }) {
    console.log('getFamilyCalls ', AccountID);

    this.UserApi.GetFamilyCalls({ AccountID }, rsp => {
      //console.log("getFamilyCalls Response", rsp);

      this.emit('onGetFamilyCalls', rsp);
    });
  }

  getMediaItemsPerDayReport({ AccountID }) {
    console.log('getMediaItemsPerDayReport ', AccountID);

    this.UserApi.GetMediaItemsPerDayReport({ AccountID }, rsp => {
      //console.log("GetMediaItemsPerDayReport Response", rsp);

      this.emit('onGetMediaItemsPerDayReport', rsp);
    });
  }

  setShowCallBtn({ AccountID, Show }) {
    console.log('setShowCallBtn ', AccountID, Show);

    this.UserApi.SetShowCallBtn({ AccountID, Show }, rsp => {
      console.log('setShowCallBtn Response');
      console.log(rsp);

      this.emit('onSetShowCallBtn', rsp);
    });
  }

  setShowCheckInBtn({ AccountID, Show }) {
    console.log('setShowCheckInBtn ', AccountID, Show);

    this.UserApi.SetShowCheckInBtn({ AccountID, Show }, rsp => {
      console.log('SetShowCheckInBtn Response');
      console.log(rsp);

      this.emit('onSetShowCheckInBtn', rsp);
    });
  }

  setShowEmergencyBtn({ AccountID, Show }) {
    console.log('setShowEmergencyBtn ', AccountID, Show);

    this.UserApi.SetShowEmergencyBtn({ AccountID, Show }, rsp => {
      console.log('SetShowEmergencyBtn Response');
      console.log(rsp);

      this.emit('onSetShowEmergencyBtn', rsp);
    });
  }

  saveToLocalStorage() {
    console.log('saveToLocalStorage');

    localStorage.UserID = this.UserID;
    localStorage.UserAccountID = this.UserAccountID;
    localStorage.UserFirstName = this.UserFirstName;
    localStorage.UserLastName = this.UserLastName;
    localStorage.UserFullName = this.UserFullName;
    localStorage.UserEmail = this.UserEmail;
    localStorage.UserCreatedDate = this.UserCreatedDate;
    localStorage.UserTelephone = this.UserTelephone;
    localStorage.UserAddress = this.UserAddress;
    localStorage.UserIsAdmin = this.UserIsAdmin;
    localStorage.UserIsOnDuty = this.UserIsOnDuty;
    localStorage.UserOnlyUrgent = this.UserOnlyUrgent;
    localStorage.UserAllPatients = this.UserAllPatients;
    localStorage.UserImage = this.UserImage;

    localStorage.ShowCallButton = this.ShowCallButton;
    localStorage.ShowCheckInButton = this.ShowCheckInButton;
    localStorage.ShowEmergencyButton = this.ShowEmergencyButton;

    localStorage.UsersList = this.UsersList;
  }
}

const userStore = new UserStore();

export default userStore;
